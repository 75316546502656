import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// Components
import SideBar from "./SideBar";
import MainEditProductsPage from "./EditProducts/MainEditProductsPage";
import EditHome from "./EditHome/EditHome";
import LogAuthAction from "./LogAuthAction/LogAuthAction";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Helper
import { getCookie, clearCookie } from "../../utils/Cookie";
// Css
import "./managementContent.scss";

export default function ManagementContent(props) {
    const navigate = useNavigate();

    // [ Auth ]
    const [showContent, setShowContent] = useState(false);
    // [ Show page ]
    const [showPage, setShowPage] = useState("home");
    // [ User name ]
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        if (props.adminSignedIn) {
            setShowContent(true);
        } else {
            // Check if it's already signed in
            const info = getCookie();

            if (info && info.user && info.x) {
                callAuthSignInEndpoint(info);
                setUserName(info.user);
            } else {
                // Clear cookies
                const time = new Date().toUTCString();
                clearCookie("name", "", time);
                clearCookie("user", "", time);
                clearCookie("x", "", time);

                // Navigate back to log in page
                navigate("/management");
            }
        }
    }, []);

    // Endpoint
    async function callAuthSignInEndpoint(info) {
        await axios
            .post(
                `${process.env.REACT_APP_API}/accounts/checkAuth`,
                {
                    type: "admin",
                    userName: info.user,
                },
                {
                    headers: {
                        "x-access-token": info.x,
                        "x-api-key": process.env.REACT_APP_API_KEY
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    props.setUserFullName(info.name);
                    setShowContent(true);

                    if (res.data.type === "website main auth") {
                        props.setMainAdminSignedIn(true);
                    }
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 403) {
                    // Clear cookies
                    const time = new Date().toUTCString();
                    clearCookie("name", "", time);
                    clearCookie("user", "", time);
                    clearCookie("x", "", time);

                    // Navigate back to log in page
                    navigate("/management");
                }
            });
    }

    return (
        <div id="page-management-content" className="flex-column v-center page">
            <div className="flex-column h-center v-center page-content">
                {showContent && (
                    <div className="flex">
                        <SideBar
                            mainAdminSignedIn={props.mainAdminSignedIn}
                            showPage={showPage}
                            setShowPage={setShowPage}
                        />
                        {showPage === "home" && <EditHome userName={userName} />}
                        {showPage === "product" && <MainEditProductsPage userName={userName} />}
                        {showPage === "log" && <LogAuthAction />}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    )
}