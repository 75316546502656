// [ Cookies ]
export const getCookie = () => {
    let cookie = document.cookie;
    cookie = cookie.split("; ");

    if (cookie.length === 1 && cookie[0] === "") {
        return null;
    } else {
        const info = {};
        cookie.forEach((item) => {
            const str = item.split("=");
            info[str[0]] = str[1];
        });

        return info;
    }
};
export const clearCookie = (name, value, expires) => {
    document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
}; 