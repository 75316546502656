import { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from 'xlsx';
// Icon
import { CgClose } from "react-icons/cg";
// Category, Localized product types
import { category, localizedProductTypes } from "../../Data";
// Css
import "./addFromFile.scss";

export default function AddFromFile(props) {
  const [types, setTypes] = useState(new Set());
  const [formData, setFormData] = useState({});
  // Validation
  const [isValid, setIsValid] = useState({
    name: true,
    model: true,
    type: true,
    color: true,
    hex: true,
    // Multiple inputs
    links: true,
    originalPrice: true,
    salePrice: true,
    images: true,
    otherPics: true,
    shorts: true,
    specs: true,
  });
  const [allValid, setAllValid] = useState(true);
  const [addSuccess, setAddSuccess] = useState(false);

  useEffect(() => {
    getTypes();
  }, []);

  const getTypes = () => {
    const formTypes = new Set();

    for (const list of category) {
      for (const item of list.type) {
        formTypes.add(item);
      }
    }

    setTypes(formTypes);
  }

  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      /* Update state */
      processExcelData(data);
    };
    reader.readAsBinaryString(file);
  };

  const processExcelData = (data) => {
    // Assuming the first row is headers
    const headers = data[0];
    const rows = data.slice(1);

    // Map the rows to objects
    const items = rows.map(row => {
      const item = {};
      row.forEach((cell, index) => {
        item[headers[index]] = cell;
      });
      return item;
    });

    checkValid(items)
  };

  // Check for valid http url
  const isValidHttpUrl = (string) => {
    if (string === undefined) {
      return true;
    }

    try {
      const url = new URL(string);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (err) {
      return false;
    }
  };

  const checkValid = (data) => {
    let allValid = true;
    const currentValid = isValid;
    const listData = [];

    for (let i = 0; i < data.length; i++) {
      const currentData = {};

      for (const key of Object.keys(isValid)) {
        // [ Product name/model/color ]
        if (key === "name" || key === "model" || key === "color") {
          const value = data[i][localizedProductTypes[key]];

          if (!value || (typeof value === "string" && value.includes(","))) {
            allValid = false;
            currentValid[key] = false;
          } else {
            currentValid[key] = true;
            currentData[key] = value;
          }
        } else if (key === "hex") {
          const value = data[i][localizedProductTypes[key]];
          const regex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

          if (value && !regex.test(value)) {
            allValid = false;
            currentValid[key] = false;
          } else {
            currentValid[key] = true;
            currentData[key] = value;
          }
        }
        // [ Product types ]
        else if (key === "type") {
          let exist = true;
          const values = data[i][localizedProductTypes[key]];

          if (!values) {
            exist = false;
          } else {
            for (const item of values.split(",")) {
              if (!types.has(item)) {
                exist = false;
                break;
              }
            }
          }

          if (!exist) {
            allValid = false;
            currentValid[key] = false;
          } else {
            currentValid[key] = true;
            currentData[key] = values.split(",");
          }
        }
        // [ Original/Sale price ]
        else if (key === "originalPrice" || key === "salePrice") {
          const value = data[i][localizedProductTypes[key]];

          if (!value || typeof value !== 'number' || isNaN(value)) {
            allValid = false;
            currentValid[key] = false;
          } else {
            currentValid[key] = true;
            currentData[key] = value;
          }
        }
        // [ Links ]
        else if (key === "links") {
          const rutenLink = data[i][localizedProductTypes.rutenLink];
          const shopeeLink = data[i][localizedProductTypes.shopeeLink];
          const pcstoreLink = data[i][localizedProductTypes.pcstoreLink];
          const momoLink = data[i][localizedProductTypes.momoLink];
          const rakutenLink = data[i][localizedProductTypes.rakutenLink];

          if (
            !isValidHttpUrl(rutenLink) ||
            !isValidHttpUrl(shopeeLink) ||
            !isValidHttpUrl(pcstoreLink) ||
            !isValidHttpUrl(momoLink) ||
            !isValidHttpUrl(rakutenLink)
          ) {
            allValid = false;
            currentValid[key] = false;
          } else {
            currentValid[key] = true;
            currentData.rutenLink = rutenLink ? rutenLink : null;
            currentData.shopeeLink = shopeeLink ? shopeeLink : null;
            currentData.pcstoreLink = pcstoreLink ? pcstoreLink : null;
            currentData.momoLink = momoLink ? momoLink : null;
            currentData.rakutenLink = rakutenLink ? rakutenLink : null;
          }
        }
        // [ Shorts ]
        else if (key === "shorts") {
          let exist = true;
          const values = data[i][localizedProductTypes[key]];

          if (!values) {
            exist = false;
          } else {
            for (const item of values.split(",")) {
              if (item === "") {
                exist = false;
                break;
              }
            }
          }

          if (!exist) {
            allValid = false;
            currentValid[key] = false;
          } else {
            currentValid[key] = true;
            currentData[key] = values ? values.split(",") : values;
          }
        }
        // [ Specs ]
        else if (key === "specs") {
          let exist = true;
          const specsInfo = {};
          const specsPart = data[i][localizedProductTypes.specsPart];
          const specsMaterial = data[i][localizedProductTypes.specsMaterial];
          const specsPros = data[i][localizedProductTypes.specsPros];

          if (!specsPart && !specsMaterial && !specsPros) {
            exist = true;
          } else if (
            (specsPart && (!specsMaterial || !specsPros)) ||
            (specsMaterial && (!specsPart || !specsPros)) ||
            (specsPros && (!specsPart || !specsMaterial))
          ) {
            exist = false;
          } else {
            const specsPartList = specsPart.split(",");
            const specsMaterialList = specsMaterial.split(",");
            const specsProsList = specsPros.split(",");

            if (
              specsPartList.length !== specsMaterialList.length ||
              specsMaterialList.length !== specsProsList.length ||
              specsPartList.length !== specsProsList.length
            ) {
              exist = false;
            }

            for (let j = 0; j < specsPartList.length; j++) {
              if (!specsPartList[j] || !specsMaterialList[j] || !specsProsList[j]) {
                exist = false;
                break;
              }
              specsInfo[specsPartList[j]] = {
                advantage: specsProsList[j],
                material: specsMaterialList[j]
              }
            }
          }

          if (!exist) {
            allValid = false;
            currentValid[key] = false;
          } else if (!specsPart && !specsMaterial && !specsPros) {
            currentValid[key] = true;
            currentData.specs = null;
          } else {
            currentValid[key] = true;
            currentData.specs = specsInfo;
          }
        }
      }

      if (!allValid) {
        break;
      }
      listData.push(currentData);
    }

    setIsValid(currentValid);
    setFormData({ data: listData, userName: props.userName });
    setAllValid(allValid);
  }


  // A function to call an API endpoint and add items to your database
  async function callAddProductEndpoint() {
    await axios
      .post(`${process.env.REACT_APP_API}/products/addFromFile`, formData, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          setAddSuccess(true);
        }
      })
      .catch(() => { });
  }

  const handleSubmit = () => {
    if (allValid) {
      callAddProductEndpoint();
    }
  }

  const getInvalidMessage = () => {
    const invalidField = [];

    for (const [key, value] of Object.entries(isValid)) {
      if (!value) {
        invalidField.push(localizedProductTypes[key]);
      }
    }

    return "請檢查 [ " + invalidField + " ] 欄位的資料是否輸入正確"
  }

  return (
    <div id="management-add-from-file" className="flex h-center v-center modal">
      {!addSuccess && (
        <div className="flex-column modal-content">
          <CgClose
            className="icon close"
            onClick={() => props.setShowAddFromFile(false)}
          />
          <h2>上傳檔案</h2>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
          {!allValid && <p className="invalid-msg">{getInvalidMessage()}</p>}
          <button
            className="button1 add-product"
            disabled={!allValid}
            onClick={handleSubmit}>
            添加商品
          </button>
        </div>
      )}
      {addSuccess && (
        <div className="flex-column h-center v-center modal-content success-pop-up">
          <a>商品添加成功</a>
          <button
            className="button1 add-product"
            onClick={() => {
              props.setShowAddFromFile(false);
              props.setShouldRefresh(true);
            }}
          >
            確認
          </button>
        </div>
      )}
    </div>
  )
}