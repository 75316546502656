
import axios from "axios";

// [ Captcha ]
export const verifyCaptcha = async (token, setPassCaptcha, setError) => {
    // Env
    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

    await axios.post(
        `${process.env.REACT_APP_API}/proxy/verifyCaptcha`,
        { token },
        {
            headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
        .then((res) => {
            if (res.status === 200) {
                setError(null);
                setPassCaptcha(res.data.success);
            }
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                setError("請通過驗證, 確認您不是機器人, 謝謝");
                setPassCaptcha(false);
            } else {
                setError(
                    "很抱歉, 暫時無法發送您的消息, 請稍後再試或者聯絡我們: " +
                    contactEmail
                );
                setPassCaptcha(false);
            }
        });
}