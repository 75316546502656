import React from "react";

export default function SideBar(props) {
    return (
        <div className="flex-column side-bar">
            <a className={props.showPage === "home" ? "current" : ""}
                onClick={() => props.setShowPage("home")}>
                首頁內容
            </a>
            <a className={props.showPage === "product" ? "current" : ""}
                onClick={() => props.setShowPage("product")}>
                商品資訊
            </a>
            {props.mainAdminSignedIn && (
                <a className={props.showPage === "log" ? "current" : ""}
                    onClick={() => props.setShowPage("log")}>
                    操作記錄
                </a>
            )}
        </div>
    )
}