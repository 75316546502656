import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Images
import img1 from "../../files/images/homepage/home-1.png";
import img2 from "../../files/images/homepage/home-2.png";
import img3 from "../../files/images/homepage/home-3.png";
// Carousel
import Carousel from "../../widgets/Carousel/Carousel";
// Icons
import { FcCheckmark } from "react-icons/fc";
// Css
import "./home.scss";

export default function Home() {
  const navigate = useNavigate();

  const [desktopBanner, setDesktopBanner] = useState([]);
  const [mobileBanner, setMobileBanner] = useState([]);

  useEffect(() => {
    // Get album content
    callGetHomepageBanner();
  }, []);

  // Endpoint
  async function callGetHomepageBanner() {
    await axios
      .get(`${process.env.REACT_APP_API}/images/homepageBanner`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setDesktopBanner(res.data.desktop);
          setMobileBanner(res.data.mobile);
        }
      })
      .catch((err) => {
        if (err.response && (err.response.status === 403 || err.response.status === 500)) {
          console.log("抱歉, 這個功能出了一點狀況, 麻煩聯絡一下網頁工程師, 謝謝！")
        }
      });
  }

  const goCategoryPage = (category) => {
    navigate("/product-list/" + category);
    window.scrollTo(0, 0);
  };

  return (
    <div id="page-home" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        {/* Carousel */}
        <Carousel
          autoSlide
          carousels={desktopBanner}
          mobileCarousels={mobileBanner}
        />

        {/* Video Section */}
        <div className="flex-column h-center v-center video-section">
          <div className="flex h-center title">
            <div className="line" />
            <p>我們的用心, 你看得見</p>
          </div>
          <iframe
            src="https://www.youtube.com/embed/KCJXnJNUFNU?si=aV1pg81eyYqGrc0g&autoplay=1&mute=1&loop=1&playsinline=1&rel=0&playlist=KCJXnJNUFNU"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>

        {/* Intro */}
        <div className="flex-column v-center intro">
          <div className="flex-column v-center main-text">
            <h2>您的每一步, 由我來守護</h2>
            <a>
              自1972年以來，得裕盛業堅守企業誠信的文化經營，並透過「純手工」產品與「品質保障」，悉心完成客戶的每一雙「使命」。
              Toping 專業安全鞋，以守護者為志，保護您每一步的安全。
            </a>
          </div>
          <div className="flex text-cont">
            <div className="flex v-center text">
              <FcCheckmark />
              <a>真台灣原廠製造</a>
            </div>
            <div className="flex v-center text">
              <FcCheckmark />
              <a>符合CNS國家標準規範</a>
            </div>
            <div className="flex v-center text">
              <FcCheckmark />
              <a>材質皆通ISO認證</a>
            </div>
            <div className="flex v-center text">
              <FcCheckmark />
              <a>安全可靠</a>
            </div>
          </div>
        </div>

        {/* Category - 專業安全鞋/舒適生活鞋/器材與用品 */}
        <div className="flex h-center v-center category">
          <img src={img1} onClick={() => goCategoryPage("專業安全鞋")} />
          <img src={img2} onClick={() => goCategoryPage("舒適生活鞋")} />
          <img src={img3} onClick={() => goCategoryPage("器材與用品")} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
