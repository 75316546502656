import React, { useState, useEffect } from "react";
import axios from "axios";
// Carousel
import Carousel from "../../../widgets/Carousel/Carousel";
// Pop up
import PopUp from "../../../widgets/PopUps/PopUps";
// Css
import "./editHome.scss";

export default function EditHome(props) {
    // Banners
    const [desktopBanner, setDesktopBanner] = useState([]);
    const [mobileBanner, setMobileBanner] = useState([]);
    const [error, setError] = useState("");
    // Order indexes
    const [currentDTIndex, setCurrentDTIndex] = useState(1);
    const [currentMIndex, setCurrentMIndex] = useState(1);
    const [currentDTOrder, setCurrentDTOrder] = useState(1);
    const [currentMOrder, setCurrentMOrder] = useState(1);
    // Links
    const [desktopLink, setDesktopLink] = useState("");
    const [mobileLink, setMobileLink] = useState("");
    const [validDTLink, setValidDTLink] = useState(true);
    const [validMLink, setValidMLink] = useState(true);
    // Loading
    const [loadingDT, setLoadingDT] = useState(false);
    const [loadingM, setLoadingM] = useState(false);
    // Pop up
    const [showPopUp, setShowPopUp] = useState(false);
    const [popUpType, setPopUpType] = useState(null);
    // Refresh
    const [shouldRefresh, setShouldRefresh] = useState(false);

    useEffect(() => {
        // Get album content
        callGetHomepageBanner();
    }, []);

    useEffect(() => {
        setCurrentDTOrder(currentDTIndex + 1);
        setValidDTLink(true);

        if (desktopBanner[currentDTIndex]) {
            const link = desktopBanner[currentDTIndex].linkURL;
            setDesktopLink(link ? link : "");
        }
    }, [currentDTIndex]);

    useEffect(() => {
        setCurrentMOrder(currentMIndex + 1);
        setValidMLink(true);

        if (mobileBanner[currentMIndex]) {
            const link = mobileBanner[currentMIndex].linkURL
            setMobileLink(link ? link : "");
        }
    }, [currentMIndex]);

    useEffect(() => {
        if (shouldRefresh) {
            window.location.reload();
            window.scrollTo(0, 0);
        }
    }, [shouldRefresh]);

    // Endpoints
    async function callGetHomepageBanner() {
        await axios
            .get(`${process.env.REACT_APP_API}/images/homepageBanner`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const linkDT = res.data.desktop.length > 0 ? res.data.desktop[0].linkURL : "";
                    const linkM = res.data.mobile.length > 0 ? res.data.mobile[0].linkURL : "";

                    setDesktopBanner(res.data.desktop);
                    setMobileBanner(res.data.mobile);
                    setDesktopLink(linkDT ? linkDT : "");
                    setMobileLink(linkM ? linkM : "");
                }
            })
            .catch((err) => {
                if (err.response && (err.response.status === 403 || err.response.status === 500)) {
                    setError("抱歉, 這個功能出了一點狀況, 麻煩聯絡一下網頁工程師, 謝謝！")
                }
            });
    }
    async function callUpdateHomepageBanner(action, type) {
        let data = null;
        let valid = true;
        const info = type === "desktop" ? desktopBanner[currentDTIndex] : mobileBanner[currentMIndex];

        // Change image order
        if (action === "change-order") {
            data = {
                currentOrder: info.order,
                newOrder: type === "desktop" ? currentDTOrder : currentMOrder,
                imageId: info.id,
                page: info.page,
                type: info.type,
                device: info.device,
            }
        }
        // Delete image
        else if (action === "delete") {
            data = {
                currentOrder: info.order,
                imageId: info.id,
                page: info.page,
                type: info.type,
                device: info.device,
            }
        }
        // Add link
        else if (action === "add-link") {
            const link = type === "desktop" ? desktopLink : mobileLink;

            data = {
                link,
                imageId: info.id,
                device: info.device,
            }
            valid = checkValidLink(link);
            type === "desktop" ? setValidDTLink(valid) : setValidMLink(valid);
        }

        if (valid) {
            await axios
                .post(`${process.env.REACT_APP_API}/images/updateHomepageBanner`,
                    {
                        action,
                        data,
                        userName: props.userName
                    },
                    {
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        setShowPopUp(true);
                        setPopUpType(action);
                    }
                })
                .catch((err) => {
                    if (err.response && (err.response.status === 403 || err.response.status === 500)) {
                        alert("抱歉, 這個功能出了一點狀況, 麻煩聯絡一下網頁工程師, 謝謝！")
                    }
                });
        }
    }
    async function callAddHomepageBanner(type, file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('page', "Homepage");
        formData.append('type', "Banner");
        formData.append('device', type);
        formData.append('userName', props.userName)

        // Set loading
        if (type === "desktop") {
            setLoadingDT(true);
        } else {
            setLoadingM(true);
        }

        await axios
            .post(`${process.env.REACT_APP_API}/proxy/imageUpload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "x-api-key": process.env.REACT_APP_API_KEY
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setShowPopUp(true);
                    setPopUpType("add")
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 500) {
                    alert("抱歉, 這個功能出了一點狀況, 麻煩聯絡一下網頁工程師, 謝謝！")
                }
            });
    }

    // Handle desktop order change
    const handleOrderChange = (event, type) => {
        // Convert input to integer
        const value = parseInt(event.target.value, 10);

        // Check if the conversion gave a valid number
        if (!isNaN(value)) {
            if (type === "desktop") {
                if (value >= 1 && value <= desktopBanner.length) {
                    setCurrentDTOrder(value);
                } else {
                    setCurrentDTOrder(Math.max(1, Math.min(value, desktopBanner.length)));
                }
            } else if (type === "mobile") {
                if (value >= 1 && value <= mobileBanner.length) {
                    setCurrentMOrder(value);
                } else {
                    setCurrentMOrder(Math.max(1, Math.min(value, mobileBanner.length)));
                }
            }
        }
        // Optionally reset or handle invalid input differently
        else {
            if (type === "desktop") {
                setCurrentDTOrder(currentDTIndex + 1);
            } else if (type === "mobile") {
                setCurrentMOrder(currentMIndex + 1);
            }
        }
    };

    // Handle file input change
    const handleFileChange = (event, type) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            callAddHomepageBanner(type, file);
        }
    };

    // Handle file input change
    const handleLinkChange = (event, type) => {
        const newLink = event.target.value;

        if (type === "desktop") {
            setDesktopLink(newLink);
        } else {
            setMobileLink(newLink);
        }
    };

    // Check if link is valid
    const checkValidLink = (link) => {
        if (link === "") {
            return true;
        }

        try {
            new URL(link);

            return true;
        } catch (err) {
            return false;
        }
    }

    // Pop up
    const popUpContent = () => {
        if (popUpType === "change-order") {
            return <a>更新成功</a>;
        } else if (popUpType === "delete") {
            return <a>刪除成功</a>;
        } else if (popUpType === "add-link") {
            return <a>連結更新成功</a>;
        } else {
            return <a>圖片上傳成功</a>;
        }
    };
    const hidePopUp = () => {
        setShowPopUp(false);
        setShouldRefresh(true);
    };

    return (
        <div id="edit-home" className="flex-column v-center">
            {/* Desktop */}
            <div className="flex-column v-center banners">
                <a className="title">電腦版橫幅: </a>
                <a>(尺寸: 2560 x 960)</a>
                {desktopBanner.length > 0 && (
                    <Carousel
                        carousels={desktopBanner}
                        mobileCarousels={desktopBanner}
                        updateIndex={setCurrentDTIndex}
                    />
                )}
                {desktopBanner.length === 0 && (
                    <div className="flex h-center v-center empty_banner">沒有圖片</div>
                )}
                <div className="flex v-center">
                    {desktopBanner.length > 0 && (
                        <div className="flex-column">
                            <div className="flex v-center inputs">
                                {desktopBanner.length > 0 && (
                                    <div className="flex v-center">
                                        <div className="flex">
                                            <a className="input-title">更換順序:</a>
                                            <input
                                                className="order"
                                                value={currentDTOrder}
                                                onChange={(event) => handleOrderChange(event, "desktop")}
                                                placeholder="輸入數字"
                                            />
                                        </div>
                                        <button className="button1 update" onClick={() => callUpdateHomepageBanner("change-order", "desktop")}>確定</button>
                                        <button className="button1 delete" onClick={() => callUpdateHomepageBanner("delete", "desktop")}>刪除圖片</button>
                                    </div>
                                )}
                            </div>
                            <div className="flex h-center v-center inputs">
                                <input
                                    type="text"
                                    className={`link-input${!validDTLink ? " invalid-input" : ""}`}
                                    placeholder="請輸入連結, 若無連結請留空"
                                    value={desktopLink}
                                    onChange={(event) => handleLinkChange(event, "desktop")}
                                />
                                <button className="button1 add-link" onClick={() => callUpdateHomepageBanner("add-link", "desktop")}>添加連結</button>
                            </div>
                            {!validDTLink && <a className="error-msg">連結格式不正確, 請再確認一下</a>}
                        </div>
                    )}
                    {!loadingDT &&
                        <div className="flex v-center file-input-container">
                            <label
                                className="button1 upload"
                                htmlFor="desktopFileInput"
                                style={{ marginLeft: desktopBanner.length > 0 ? "30px" : "0" }}>
                                上傳新圖片
                            </label>
                            <input id="desktopFileInput" type="file" className="file-input" onChange={(event) => handleFileChange(event, "desktop")} />
                        </div>
                    }
                    {loadingDT && <a className="loading" style={{ marginLeft: desktopBanner.length > 0 ? "30px" : "-50%" }}>(上傳中...)</a>}
                </div>
            </div>
            {/* Tablet / Phone */}
            <div className="flex-column v-center banners">
                <a className="title">平板/手機版橫幅: </a>
                <a>(尺寸: 1536 x 900)</a>
                {mobileBanner.length > 0 && (
                    <Carousel
                        carousels={mobileBanner}
                        mobileCarousels={mobileBanner}
                        updateIndex={setCurrentMIndex}
                    />
                )}
                {mobileBanner.length === 0 && (
                    <div className="flex h-center v-center empty_banner">沒有圖片</div>
                )}
                <div className="flex v-center">
                    {mobileBanner.length > 0 && (
                        <div className="flex-column">
                            <div className="flex v-center inputs">
                                {mobileBanner.length > 0 && (
                                    <div className="flex">
                                        <div className="flex v-center">
                                            <a className="input-title">更換順序:</a>
                                            <input
                                                className="order"
                                                value={currentMOrder}
                                                onChange={(event) => handleOrderChange(event, "mobile")}
                                                placeholder="輸入數字"
                                            />
                                        </div>
                                        <button className="button1 update" onClick={() => callUpdateHomepageBanner("change-order", "mobile")}>確定</button>
                                        <button className="button1 delete" onClick={() => callUpdateHomepageBanner("delete", "mobile")}>刪除圖片</button>
                                    </div>
                                )}
                            </div>
                            <div className="flex h-center v-center inputs">
                                <input
                                    type="text"
                                    className={`link-input${!validMLink ? " invalid-input" : ""}`}
                                    placeholder="請輸入連結, 若無連結請留空"
                                    value={mobileLink}
                                    onChange={(event) => handleLinkChange(event, "mobile")}
                                />
                                <button className="button1 add-link" onClick={() => callUpdateHomepageBanner("add-link", "mobile")}>添加連結</button>
                            </div>
                            {!validMLink && <a className="error-msg">連結格式不正確, 請再確認一下</a>}
                        </div>
                    )}
                    {!loadingM &&
                        <div className="flex v-center file-input-container">
                            <label
                                className="button1 upload"
                                htmlFor="mobileFileInput"
                                style={{ marginLeft: mobileBanner.length > 0 ? "30px" : "0" }}>
                                上傳新圖片
                            </label>
                            <input id="mobileFileInput" type="file" className="file-input" onChange={(event) => handleFileChange(event, "mobile")} />
                        </div>
                    }
                    {loadingM && <a className="loading" style={{ marginLeft: mobileBanner.length > 0 ? "30px" : "-50%" }}>(上傳中...)</a>}
                </div>
            </div>
            {error && <a className="error-msg">{error}</a>}
            {showPopUp && (
                <PopUp popUpContent={popUpContent} hidePopUp={hidePopUp} />
            )}
        </div>
    )
}