import React, { useState, useEffect } from "react";
// Icons
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// Css
import "./carousel.scss";

export default function Carousel(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 925);
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = props.carousels.length;

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 925);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.updateIndex) {
            props.updateIndex(currentSlide);
        }
    }, [currentSlide]);

    useEffect(() => {
        if (props.autoSlide) {
            const interval = setInterval(() => {
                moveSlide(1);
            }, 5000);
    
            // Return a cleanup function that clears the interval
            return () => clearInterval(interval);
        }
    }, [props.autoSlide, currentSlide, totalSlides]);

    // Elements
    const slideElements = () => {
        const list = !isMobile ? props.carousels : props.mobileCarousels;
        return list.map((item, index) => (
            <div
                key={index}
                className={`carousel-slide${index === currentSlide ? " active" : ""}${item.linkURL ? " link" : ""}`}
                onClick={() => HandleClickOnSlide(item)}
            >
                <img src={item.imageURL} alt={`Slide ${index}`} />
            </div>
        ));
    };

    const indicatorElements = () => {
        const list = !isMobile ? props.carousels : props.mobileCarousels;
        return list.map((item, index) => (
            <span
                key={index}
                className={`indicator ${currentSlide === index ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
            ></span>
        ));
    };

    // Handle click on slide
    const HandleClickOnSlide = (item) => {
        if (item.linkURL) {
            window.location.href = item.linkURL;
        }
    };

    // Controls
    const moveSlide = (direction) => {
        setCurrentSlide((prevSlide) => (prevSlide + direction + totalSlides) % totalSlides);
    };

    const goToSlide = (slideIndex) => {
        setCurrentSlide(slideIndex);
    };

    return (
        <div id="carousel">
            <button className="flex h-center v-center carousel-button prev" onClick={() => moveSlide(-1)}>
                <FaChevronLeft />
            </button>
            <div className="carousel-slides" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slideElements()}
            </div>
            <button className="flex h-center v-center carousel-button next" onClick={() => moveSlide(1)}>
                <FaChevronRight />
            </button>
            <div className="carousel-indicators">
                {indicatorElements()}
            </div>
        </div>
    );
}
