import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// Components
import SignIn from "../User/SignInUp/SignIn";
// Helper
import { getCookie, clearCookie } from "../../utils/Cookie";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Css
import "./management.scss";

export default function ProductManagement(props) {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if it's already signed in
    const info = getCookie();

    if (info && info.user && info.x) {
      callAuthSignInEndpoint(info);
    }
  }, []);

  // Endpoint
  async function callAuthSignInEndpoint(info) {
    await axios
      .post(
        `${process.env.REACT_APP_API}/accounts/checkAuth`,
        {
          type: "admin",
          userName: info.user,
        },
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          props.setUserFullName(info.name);
          props.setAdminSignedIn(true);

          // Navigate to edit page
          navigate("/management/edit");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          // Clear cookies
          const time = new Date().toUTCString();
          clearCookie("name", "", time);
          clearCookie("user", "", time);
          clearCookie("x", "", time);
        }
      });
  }

  return (
    <div id="page-management" className="flex-column v-center page">
      <div className="flex-column h-center v-center page-content">
        {/* Sign in */}
        <SignIn
          management={true}
          insidePage={true}
          showForgetFileds={false}
          setAdminSignedIn={props.setAdminSignedIn}
          setMainAdminSignedIn={props.setMainAdminSignedIn}
        />
      </div>
      <Footer />
    </div>
  );
}
