import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Components
import Nav from "../widgets/Nav/Nav";
import SideButtons from "../widgets/SideButtons/SideButtons";
import SignIn from "./User/SignInUp/SignIn";
import SignInLine from "./User/SignInUp/SignInLine";
import SignUp from "./User/SignInUp/SignUp";
import ForgotUserName from "./User/SignInUp/ForgotUserName";
import ForgotPassword from "./User/SignInUp/ForgotPassword";
import ResetPassword from "./User/SignInUp/Verification/ResetPassword";
import VerifyEmail from "./User/SignInUp/Verification/VerifyEmail";
import Home from "./Home/Home";
import Catalog from "./Catalog/Catalog";
import Locations from "./Locations/Locations";
import QA from "./QA/QA";
import AboutUs from "./AboutUs/AboutUs";
import Privacy from "./Privacy/Privacy";
import ContactUs from "./ContactUs/ContactUs";
import ProductList from "./ProductList/ProductList";
import SearchResult from "./ProductList/SearchResult";
import ProductInfo from "./ProductList/ProductInfo";
import UserInfo from "./User/UserInfo/UserInfo";
import OrderTracking from "./User/OrderTracking/OrderTracking";
import OrderDetail from "./User/OrderTracking/OrderDetail";
import Cart from "./CheckOut/Cart";
import ShippingInfo from "./CheckOut/ShippingInfo";
import OrderComplete from "./CheckOut/OrderComplete";
import Management from "./Management/Management";
import ManagementContent from "./Management/ManagementContent";
// Helper
import { getCookie, clearCookie } from "../utils/Cookie";
// Css
import "./app.css";
import "../widgets/Animations/keyframes.css";

export default function App() {
  // [ User full name ]
  const [userFullName, setUserFullName] = useState(null);
  // [ Toggle sign in/sign up/forget user name/forget password modal ]
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgotUserName, setShowForgotUserName] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  // [ Populate sign in animation ]
  const [showSignInAnimation, setShowSignInAnimation] = useState(false);
  // [ sign in ]
  const [signedIn, setSignedIn] = useState(false);
  const [adminSignedIn, setAdminSignedIn] = useState(false);
  const [mainAdminSignedIn, setMainAdminSignedIn] = useState(false);
  // [ Social ]
  const [showSignUpSocial, setShowSignUpSocial] = useState(false);
  const [socialInfo, setSocialInfo] = useState(null);
  // [ Click category from nav ]
  const [clickNav, setClickNav] = useState(false);
  // [ Cart ]
  const [cartQuantity, setCartQuantity] = useState(0);
  const [toggleCart, setToggleCart] = useState(false);
  const [refreshCart, setRefreshCart] = useState(false);
  // [ New order detail ]
  const [newOrderDetail, setNewOrderDetail] = useState(null);
  // [ Search result ]
  const [searchResult, setSearchResult] = useState([]);
  const [updateSearch, setUpdateSearch] = useState(false);

  // Auth sign in
  useEffect(() => {
    const info = getCookie();

    if (info && info.user && info.x) {
      callAuthSignInEndpoint(info);
    }
  }, []);

  // Endpoint
  async function callAuthSignInEndpoint(info) {
    await axios
      .post(
        `${process.env.REACT_APP_API}/accounts/checkAuth`,
        {
          type: "user",
          userName: info.user,
        },
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserFullName(info.name);
          setSignedIn(true);
        }
      })
      .catch((err) => {
        // Clear cookies
        if (err.response && err.response.status === 403) {
          const time = new Date().toUTCString();
          clearCookie("name", "", time);
          clearCookie("user", "", time);
          clearCookie("x", "", time);
        }
      });
  }

  return (
    <Router>
      <Nav
        userFullName={userFullName}
        signedIn={signedIn}
        socialInfo={socialInfo}
        cartQuantity={cartQuantity}
        toggleCart={toggleCart}
        refreshCart={refreshCart}
        setUserFullName={setUserFullName}
        setShowSignIn={setShowSignIn}
        setShowSignInAnimation={setShowSignInAnimation}
        setSignedIn={setSignedIn}
        setClickNav={setClickNav}
        setCartQuantity={setCartQuantity}
        setToggleCart={setToggleCart}
        setRefreshCart={setRefreshCart}
        setSearchResult={setSearchResult}
        setUpdateSearch={setUpdateSearch}
      />
      <SideButtons />
      {showSignIn && (
        <SignIn
          insidePage={false}
          showForgetFileds={true}
          showSignInAnimation={showSignInAnimation}
          setUserFullName={setUserFullName}
          setShowSignIn={setShowSignIn}
          setShowSignUp={setShowSignUp}
          setShowForgotPassword={setShowForgotPassword}
          setShowForgotUserName={setShowForgotUserName}
          setShowSignInAnimation={setShowSignInAnimation}
          setSignedIn={setSignedIn}
          setAdminSignedIn={setAdminSignedIn}
          setShowSignUpSocial={setShowSignUpSocial}
          setSocialInfo={setSocialInfo}
          setCartQuantity={setCartQuantity}
          setRefreshCart={setRefreshCart}
        />
      )}
      {/* {showSignUp && (
        <SignUp
          socialInfo={socialInfo}
          setUserFullName={setUserFullName}
          setShowSignIn={setShowSignIn}
          setShowSignInAnimation={setShowSignInAnimation}
          setSignedIn={setSignedIn}
          setShowSignUp={setShowSignUp}
          setShowSignUpSocial={setShowSignUpSocial}
          setSocialInfo={setSocialInfo}
        />
      )} */}
      {/* {showSignUpSocial && (
        <SignUp
          socialInfo={socialInfo}
          setUserFullName={setUserFullName}
          setShowSignIn={setShowSignIn}
          setShowSignInAnimation={setShowSignInAnimation}
          setSignedIn={setSignedIn}
          setShowSignUp={setShowSignUp}
          setShowSignUpSocial={setShowSignUpSocial}
          setSocialInfo={setSocialInfo}
        />
      )} */}
      {/* {showForgotUserName && (
        <ForgotUserName
          setShowSignIn={setShowSignIn}
          setShowForgotUserName={setShowForgotUserName}
          setShowForgotPassword={setShowForgotPassword}
          setShowSignInAnimation={setShowSignInAnimation}
        />
      )} */}
      {/* {showForgotPassword && (
        <ForgotPassword
          setShowSignIn={setShowSignIn}
          setShowForgotUserName={setShowForgotUserName}
          setShowForgotPassword={setShowForgotPassword}
          setShowSignInAnimation={setShowSignInAnimation}
        />
      )} */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route
          exact path="/line"
          element={
            <SignInLine
              setSignedIn={setSignedIn}
              setShowSignIn={setShowSignIn}
              setUserFullName={setUserFullName}
              setShowSignUpSocial={setShowSignUpSocial}
              setCartQuantity={setCartQuantity}
              setRefreshCart={setRefreshCart}
              setSocialInfo={setSocialInfo}
            />
          }
        /> */}
        <Route exact path="/catalog" element={<Catalog />} />
        <Route exact path="/location" element={<Locations />} />
        <Route exact path="/qa" element={<QA />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/contact/:page" element={<ContactUs />} />
        <Route
          exact path="/product-list/:category"
          element={
            <ProductList clickNav={clickNav} setClickNav={setClickNav} />
          }
        />
        <Route
          exact path="/search-product"
          element={
            <SearchResult
              searchResult={searchResult}
              updateSearch={updateSearch}
              setUpdateSearch={setUpdateSearch}
            />
          }
        />
        <Route
          exact path="/product/:productId/:productSelected"
          element={
            <ProductInfo
              signedIn={signedIn}
              setCartQuantity={setCartQuantity}
              setToggleCart={setToggleCart}
            />
          }
        />
        {/* <Route
          exact path="/info"
          element={
            <UserInfo
              setUserFullName={setUserFullName}
              setShowSignIn={setShowSignIn}
              setShowSignInAnimation={setShowSignInAnimation}
              setSignedIn={setSignedIn}
            />
          }
        /> */}
        {/* <Route
          exact path="/reset-password"
          element={
            <ResetPassword
              setShowSignIn={setShowSignIn}
              setShowSignInAnimation={setShowSignInAnimation}
            />
          }
        /> */}
        {/* <Route
          exact path="/verify-email"
          element={
            <VerifyEmail
              setShowSignIn={setShowSignIn}
              setShowSignInAnimation={setShowSignInAnimation}
            />
          }
        /> */}
        {/* <Route
          exact path="/order-tracking"
          element={
            <OrderTracking
              signedIn={signedIn}
              setSignedIn={setSignedIn}
              setUserFullName={setUserFullName}
              setShowSignInAnimation={setShowSignInAnimation}
              setShowSignUpSocial={setShowSignUpSocial}
              setSocialInfo={setSocialInfo}
              setCartQuantity={setCartQuantity}
              setRefreshCart={setRefreshCart}
            />
          }
        /> */}
        {/* <Route
          exact path="/order-detail/:orderNumber/:email"
          element={<OrderDetail />}
        /> */}
        {/* <Route
          exact path="/cart"
          element={
            <Cart
              refreshCart={refreshCart}
              setCartQuantity={setCartQuantity}
              setRefreshCart={setRefreshCart}
            />
          }
        /> */}
        {/* <Route
          exact path="/shipping-info"
          element={
            <ShippingInfo
              setCartQuantity={setCartQuantity}
              setRefreshCart={setRefreshCart}
              setNewOrderDetail={setNewOrderDetail}
            />
          }
        /> */}
        {/* <Route
          exact path="/order-complete"
          element={
            <OrderComplete
              newOrderDetail={newOrderDetail}
              setNewOrderDetail={setNewOrderDetail}
            />
          }
        /> */}
        {/* Managements */}
        <Route
          exact path="/management"
          element={
            <Management
              adminSignedIn={adminSignedIn}
              setAdminSignedIn={setAdminSignedIn}
              setMainAdminSignedIn={setMainAdminSignedIn}
              setUserFullName={setUserFullName}
            />
          }
        />
        <Route
          exact path="/management/edit"
          element={
            <ManagementContent
              adminSignedIn={adminSignedIn}
              mainAdminSignedIn={mainAdminSignedIn}
              setUserFullName={setUserFullName}
              setAdminSignedIn={setAdminSignedIn}
              setMainAdminSignedIn={setMainAdminSignedIn}
            />
          }
        />
      </Routes>
    </Router>
  );
}
