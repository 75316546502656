import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Icon
import { RiArrowUpSLine } from "react-icons/ri";
import { AiTwotoneMail } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import { SiLine } from "react-icons/si";
// Css
import "./sideButtons.css";

export default function SideButtons() {
  const navigate = useNavigate();

  // Show message - Fb/Line
  const [showMessage, setShowMessage] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  // Show hint - Contact us
  const [showHint, setShowHint] = useState(false);

  return (
    <div id="side-buttons">
      {/* Facebook chat */}
      <div
        className="flex h-center v-center button facebook-chat"
        onMouseOver={() => {
          setShowMessage(true);
          setMessageIndex(0);
        }}
        onMouseOut={() => setShowMessage(false)}
        onClick={() => window.open("https://www.facebook.com/profile.php?id=100082934212185", "_blank")}
      >
        <FaFacebookMessenger />
      </div>
      {/* Line chat */}
      <div
        className="flex h-center v-center button line-chat"
        onMouseOver={() => {
          setShowMessage(true);
          setMessageIndex(1);
        }}
        onMouseOut={() => setShowMessage(false)}
        onClick={() =>
          window.open("https://line.me/R/ti/p/@345ycbrl", "_blank")
        }
      >
        <SiLine />
      </div>
      {/* Messages */}
      <div
        className="h-center v-center message"
        style={{
          display: showMessage ? "flex" : "none",
          bottom: messageIndex === 0 ? "142.5px" : "97.5px",
        }}
      >
        <a>您好～產品問題歡迎與我們聯繫!（回覆時間為平日9:00 - 18:00）</a>
      </div>
      {/* Contact us */}
      <div
        className="flex h-center v-center button contact-us"
        onClick={() => navigate("/contact")}
        onMouseOver={() => setShowHint(true)}
        onMouseOut={() => setShowHint(false)}
      >
        <AiTwotoneMail />
      </div>
      <div
        className="h-center v-center message message-contact"
        style={{ display: showHint ? "flex" : "none" }}
      >
        <a>聯絡我們</a>
      </div>
      {/* Scroll to top */}
      <div
        className="flex h-center v-center button scroll-top"
        onClick={() => window.scrollTo(0, 0)}
      >
        <RiArrowUpSLine />
      </div>
    </div>
  );
}
