import React, { useState, useEffect } from "react";
import axios from "axios";
// Components
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import AddFromFile from "./AddFromFile";
// Category
import { category } from "../../Data";
// Icons
import { RiAddCircleFill } from "react-icons/ri";
// Css
import "./mainEditProductsPage.scss";

export default function MainEditProductsPage(props) {
    // [ Sort ]
    const [sort, setSort] = useState("產品型號");
    const [sortAsc, setSortAsc] = useState(true);
    // [ Products ]
    const [products, setProducts] = useState(new Map());
    const [showProducts, setShowProducts] = useState([]);
    const [editProductId, setEditProductId] = useState(null);
    // [ Add product modal ]
    const [showAddProduct, setShowAddProduct] = useState(false);
    // [ Edit product modal ]
    const [showEditProduct, setShowEditProduct] = useState(false);
    // [ Add from file modal ]
    const [showAddFromFile, setShowAddFromFile] = useState(false);
    // [ Refresh page ]
    const [shouldRefresh, setShouldRefresh] = useState(false);

    useEffect(() => {
        // Show product list
        callProductListEndpoint();
    }, []);

    useEffect(() => {
        if (shouldRefresh) {
            callProductListEndpoint();
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    // Endpoint
    async function callProductListEndpoint() {
        await axios
            .get(`${process.env.REACT_APP_API}/products/list`, {
                headers: { "x-api-key": process.env.REACT_APP_API_KEY },
            })
            .then((res) => {
                if (res.status === 200) {
                    const data = classifyProducts(res.data);
                    setProducts(data);
                    filterProducts(data, "所有商品");
                }
            })
            .catch(() => { });
    }

    // [ Handle Products ]
    // Classify products
    const classifyProducts = (data) => {
        const typeMap = new Map();
        const productMap = new Map();
        let list = [];
        let lastType = null;

        // Product colors, price, and sale
        for (let i = 0; i < data.product_info.length; i++) {
            const { productId, color, code, price, sale, image } =
                data.product_info[i];

            if (!productMap.has(productId)) {
                productMap.set(productId, {
                    color: [color],
                    code: [code],
                    price: [price],
                    sale: [sale],
                    image: [image],
                    imageIndex: 0,
                });
            } else {
                const old = productMap.get(productId);
                productMap.set(productId, {
                    color: [...old.color, color],
                    code: [...old.code, code],
                    price: [...old.price, price],
                    sale: [...old.sale, sale],
                    image: [...old.image, image],
                    imageIndex: 0,
                });
            }
        }

        // Classify products by types and add product color, price, and sale to products
        for (let i = 0; i < data.products.length; i++) {
            const { id, type } = data.products[i];
            const info = {
                ...data.products[i],
                ...productMap.get(id),
            };

            if (lastType != type) {
                if (i !== 0) {
                    typeMap.set(lastType, [...list]);
                }
                list = [info];
            } else {
                list.push(info);
            }
            lastType = type;
        }

        if (data.products.length > 0) {
            typeMap.set(lastType, [...list]);
        }

        return typeMap;
    };
    // Filter products
    const filterProducts = (data, currentSelect) => {
        let list = [];
        const productMap = new Map();

        if (currentSelect === "所有商品") {
            for (const value of data.values()) {
                for (let i = 0; i < value.length; i++) {
                    const { name, model } = value[i];
                    const distinctName = name + "_" + model;

                    if (!productMap.has(distinctName)) {
                        const productValue = value[i];
                        productValue.type = [productValue.type];

                        list.push(productValue);
                        productMap.set(distinctName, list.length - 1);
                    } else {
                        const index = productMap.get(distinctName);
                        list[index].type.push(value[i].type);
                    }
                }
            }
        } else if (
            currentSelect === "專業安全鞋" ||
            currentSelect === "舒適生活鞋" ||
            currentSelect === "其他"
        ) {
            for (let i = 0; i < category.length; i++) {
                const { name, type } = category[i];

                if (name === currentSelect) {
                    for (let j = 0; j < type.length; j++) {
                        const value = data.get(type[j]);

                        for (let k = 0; value && k < value.length; k++) {
                            const { name, model } = value[k];
                            const distinctName = name + "_" + model;

                            if (!productMap.has(distinctName)) {
                                list.push(value[k]);
                                productMap.set(distinctName, list.length - 1);
                            }
                        }
                    }
                    break;
                }
            }
        } else {
            list = data.get(currentSelect);
        }
        sortProducts(list === undefined ? [] : list, sort, sortAsc);
    };
    // Sort products
    const sortProducts = (data, currentSort, currentAsc) => {
        const field = {
            產品型號: "model",
            產品價格: "sale",
        };
        const fieldName = field[currentSort];
        let sortedData = null;

        if (fieldName === "sale") {
            sortedData = data.sort((a, b) => {
                const priceA = Math.min(...a[fieldName]);
                const priceB = Math.min(...b[fieldName]);

                if (priceA === priceB) {
                    return 0;
                }
                return currentAsc ? priceA - priceB : priceB - priceA;
            });
        } else {
            sortedData = data.sort((a, b) => {
                if (a[fieldName] === b[fieldName]) {
                    return 0;
                }

                if (currentAsc) {
                    return a[fieldName] < b[fieldName] ? -1 : 1;
                } else {
                    return b[fieldName] < a[fieldName] ? -1 : 1;
                }
            });
        }

        setShowProducts(sortedData);
    };

    // Show product list
    const getProductList = () => {
        const list = [];

        showProducts.forEach((item, productIdx) => {
            let types = "";
            item.type.forEach((type, typeIdx) => {
                types += typeIdx == 0 ? "" : ", ";
                types += type;
            });

            const colorIdx = 0;
            list.push(
                <div
                    key={"product_row" + productIdx + "_" + colorIdx}
                    className="flex"
                >
                    <a key={"product_name" + productIdx + "_" + colorIdx}>
                        {item.name}
                    </a>
                    <a key={"product_model" + productIdx + "_" + colorIdx}>
                        {item.model}
                    </a>
                    <a key={"product_price" + productIdx + "_" + colorIdx}>
                        ${item.price[colorIdx]}
                    </a>
                    <a key={"product_sale" + productIdx + "_" + colorIdx}>
                        ${item.sale[colorIdx]}
                    </a>
                    <a key={"product_type" + productIdx + "_" + colorIdx}>{types}</a>
                    <a onClick={() => openEditModal(item.id)}>詳細資料</a>
                </div>
            );
        });

        return list;
    };

    // Open edit modal
    const openEditModal = (id) => {
        setEditProductId(id);
        setShowEditProduct(true);
    }

    return (
        <div id="edit-products" className="flex-column v-center">
            {/* Add new product */}
            <div className="flex h-center v-center">
                {/* 添加新商品 */}
                <div
                    className="flex h-center v-center add-new-product"
                    onClick={() => setShowAddProduct(true)}
                >
                    <RiAddCircleFill />
                    <a>添加新商品</a>
                </div>
                {/* 從檔案導入商品 */}
                <div
                    className="flex h-center v-center add-new-product"
                    onClick={() => setShowAddFromFile(true)}
                >
                    <RiAddCircleFill />
                    <a>從檔案導入商品</a>
                </div>
            </div>
            {/* Product list */}
            <div className="flex-column product-list">
                {/* Title */}
                <div className="flex title">
                    <a>商品名稱</a>
                    <a>型號</a>
                    <a>原價</a>
                    <a>特價</a>
                    <a>產品分類</a>
                    <a></a>
                </div>
                {getProductList()}
            </div>
            {/* Add product */}
            {showAddProduct && (
                <AddProduct
                    userName={props.userName}
                    setShowAddProduct={setShowAddProduct}
                    setShouldRefresh={setShouldRefresh}
                />
            )}
            {/* Edit product */}
            {showEditProduct && (
                <EditProduct
                    userName={props.userName}
                    editProductId={editProductId}
                    setShowEditProduct={setShowEditProduct}
                    setShouldRefresh={setShouldRefresh}
                />
            )}
            {/* Add product */}
            {showAddFromFile && (
                <AddFromFile
                    userName={props.userName}
                    setShowAddFromFile={setShowAddFromFile}
                    setShouldRefresh={setShouldRefresh}
                />
            )}
        </div>
    );
}