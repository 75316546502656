import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Captcha
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { verifyCaptcha } from "../../../utils/Captcha";
// Pop up
import PopUp from "../../../widgets/PopUps/PopUps";
// Helper
import { getCookie } from "../../../utils/Cookie";
// Icons
import { FaLine, FaFacebook, FaGoogle } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
// Css
import "./signInUp.css";

export default function SignIn(props) {
  const { insidePage } = props;
  const navigate = useNavigate();

  // [ Captcha ]
  const [token, setToken] = useState(null);
  const [passCaptcha, setPassCaptcha] = useState(false);
  // [ User info ]
  // User name
  const userName = useRef("");
  // Password
  const [showPassword, setShowPassword] = useState(false);
  const password = useRef("");
  // Full name
  const [fullName, setFullName] = useState("");
  // Validation
  const [inValid, setInValid] = useState(false);
  // [ Error ]
  const [error, setError] = useState(null);
  const [popUpError, setPopUpError] = useState(null);
  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState(null);
  // [ Social ]
  const [socialType, setSocialType] = useState(null);
  const [socialInfo, setSocialInfo] = useState(null);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const environment = process.env.NODE_ENV;

  // Update password icon
  const updatePasswordIcon = () => {
    setShowPassword(!showPassword);
  };

  // Detect Enter key press
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    // Captcha
    if (passCaptcha) {
      props.management ? callSignInAuthEndpoint() : callSignInEndpoint();
      setPassCaptcha(false);
    }
  }, [passCaptcha]);

  // Submit
  async function handleSubmit() {
    setInValid(false);
    setError(null);
    if (userName.current.value === "" || password.current.value === "") {
      setInValid(true);
      setError("請輸入帳號密碼");
    } else {
      if (environment === "production") {
        verifyCaptcha(token, setPassCaptcha, setError);
      } else {
        props.management ? callSignInAuthEndpoint() : callSignInEndpoint();
      }
    }
  }

  // Endpoints
  async function callSignInEndpoint() {
    await setInValid(false);
    await axios
      .post(`${process.env.REACT_APP_API}/accounts/signIn`, {
        userName: userName.current.value,
        password: password.current.value,
        cart: getCookie(),
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 204) {
          setInValid(true);
          setError("無法登入, 帳號或密碼錯誤");
        } else if (res.status === 200) {
          // Cookie
          const time = new Date(res.data.expires).toUTCString();
          setCookie("name", res.data.fullName, time);
          setCookie("user", res.data.userName, time);
          setCookie("x", res.headers["x-access-token"], time);

          // [ Cart ]
          const cart = res.data.cart.length > 0 ? res.data.cart[0] : null;
          if (cart && cart.id && cart.expires) {
            setCookie("cart", cart.id, new Date(cart.expires));
            // Update cart quantity
            props.setCartQuantity(res.data.quantity);
            // Update refresh cart state
            props.setRefreshCart(true);
          }

          // Show sign in complete pop up
          setFullName(res.data.fullName);
          setPopUpType(1);
          setShowPopUp(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          callSendVerifyEmailEndpoint(true);
          setPopUpType(2);
          setShowPopUp(true);
        } else if (err.response && err.response.status === 429) {
          setInValid(true);
          setError(err.response.data);
        } else {
          setError("網頁錯誤, 目前無法登入, 請與我們聯絡: " + contactEmail);
        }
      });
  }
  async function callSignInAuthEndpoint() {
    await setInValid(false);
    await axios
      .post(`${process.env.REACT_APP_API}/accounts/signInAuth`, {
        userName: userName.current.value,
        password: password.current.value,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 204) {
          setInValid(true);
          setError(props.management ? "無法登入" : "無法登入, 帳號或密碼錯誤");
        } else if (res.status === 200) {
          props.setAdminSignedIn(true);

          if (res.data.type === "website main auth") {
            props.setMainAdminSignedIn(true);
          }

          // Cookie
          const time = new Date(res.data.expires).toUTCString();
          setCookie("user", res.data.userName, time);
          setCookie("x", res.headers["x-access-token"], time);

          // Navigate to edit page
          navigate("/management/edit");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          callSendVerifyEmailEndpoint(true);
          setPopUpType(2);
          setShowPopUp(true);
        } else if (err.response && err.response.status === (429 || 404)) {
          setInValid(true);
          setError(err.response.data);
        } else if (err.response && err.response.status === 500){
          setError("網頁錯誤, 目前無法登入, 請與我們聯絡: " + contactEmail);
        }
      });
  }
  async function callSendVerifyEmailEndpoint(initial) {
    await setPopUpError(null);
    await axios
      .put(`${process.env.REACT_APP_API}/accounts/verifyEmail`, {
        userName: userName.current.value,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then(() => {
        // Show resend verify email sucess
        if (!initial) {
          setPopUpError("郵件已發送");
        }
      })
      .catch(() => {
        setPopUpError(
          "網頁錯誤, 目前無法重寄驗證信, 若您看到此訊息, 請與我們聯絡: " +
            contactEmail
        );
      });
  }
  async function callSignInWithSocialEndpoint(email, source) {
    setError(null);
    await axios
      .post(`${process.env.REACT_APP_API}/accounts/signInWithSocial`, {
        email,
        source,
        cart: getCookie(),
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        // Sign in
        if (res.status === 200) {
          // Not register - go to sign up page
          if (res.data.email) {
            setPopUpType(3);
            setShowPopUp(true);
            setSocialType(source);
            setSocialInfo({ email });
          }
          // Sign in success
          else {
            // Cookie
            const time = new Date(res.data.expires).toUTCString();
            setCookie("name", res.data.fullName, time);
            setCookie("user", res.data.userName, time);
            setCookie("x", res.headers["x-access-token"], time);

            // [ Cart ]
            const cart = res.data.cart.length > 0 ? res.data.cart[0] : null;
            if (cart && cart.id && cart.expires) {
              setCookie("cart", cart.id, new Date(cart.expires));
              // Update cart quantity
              props.setCartQuantity(res.data.quantity);
              // Update refresh cart state
              props.setRefreshCart(true);
            }

            // Show sign in complete pop up
            setFullName(res.data.fullName);
            setPopUpType(1);
            setShowPopUp(true);

            // Set social info
            props.setSocialInfo({ source });
          }
        }
      })
      .catch(() => {});
  }

  // Cookie
  const setCookie = (name, value, expires) => {
    document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
  };

  // Pop up
  const popUpContent1 = () => {
    return <a>登入成功!</a>;
  };
  const popUpContent2 = () => {
    return (
      <div className="w-100">
        <a>您還沒有完成驗證, 驗證郵件將在10分鐘內發送到您的信箱。</a>
        <a>若是您等了10分鐘還是沒有收到郵件, 您可以:</a>
        <ol>
          <li>先確認您的垃圾郵件夾。</li>
          <li>
            點擊<a onClick={() => callSendVerifyEmailEndpoint(false)}>這裡</a>
            再次發送郵件。
          </li>
          <li>與我們聯繫: {contactEmail}</li>
        </ol>
        <div className="flex h-center">
          <a className="error-msg">{popUpError}</a>
        </div>
      </div>
    );
  };
  const popUpContent3 = () => {
    return (
      <div className="redirect-to-enroll">
        <a>
          系統找不到您的註冊紀錄, 點擊下方確認鍵將跳轉至註冊頁面, 將用您的
          {socialType}帳號於本系統註冊。
        </a>
      </div>
    );
  };
  const hidePopUp1 = () => {
    setShowPopUp(false);
    props.setShowSignIn(false);
    props.setUserFullName(fullName);
    props.setSignedIn(true);
    navigate("/");
  };
  const hidePopUp2 = () => {
    setShowPopUp(false);
  };
  const hidePopUp3 = () => {
    const info = socialInfo;
    info.source = socialType;

    props.setShowSignIn(false);
    props.setShowSignUpSocial(true);
    props.setSocialInfo(info);
    setShowPopUp(false);
  };
  const getPopUpContent = () => {
    if (popUpType === 1) {
      return popUpContent1();
    } else if (popUpType === 2) {
      return popUpContent2();
    } else {
      return popUpContent3();
    }
  };
  const getHidePopUp = () => {
    if (popUpType === 1) {
      hidePopUp1();
    } else if (popUpType === 2) {
      hidePopUp2();
    } else {
      hidePopUp3();
    }
  };

  // Close sign in modal
  const closeSignIn = () => {
    props.setShowSignInAnimation(false);
    props.setShowSignIn(false);
  };

  // Show sign up modal
  const showSignUp = () => {
    props.setShowSignIn(false);
    props.setShowSignUp(true);
  };

  // Show forgot user name/password modal
  const showForgotUserName = () => {
    props.setShowSignIn(false);
    props.setShowForgotUserName(true);
  };
  const showForgotPassword = () => {
    props.setShowSignIn(false);
    props.setShowForgotPassword(true);
  };

  // Facebook login
  const responseFacebook = (response) => {
    const { email } = response;

    if (email) {
      callSignInWithSocialEndpoint(email, "facebook");
    } else {
      setError("您的facebook登入有問題, 請稍後重試");
    }
  };

  // Line
  async function lineSignIn() {
    await axios
      .get(`${process.env.REACT_APP_API}/accounts/line`, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        // Sign in
        if (res.status === 200) {
          const url =
            `https://access.line.me/oauth2/v2.1/authorize?` +
            `response_type=code&` +
            `client_id=${process.env.REACT_APP_LINE_APP_IP}&` +
            `scope=openid%20email&` +
            `state=${res.data}&` +
            `redirect_uri=${"https://localhost:3000/line"}`;

          window.location.href = url;
        }
      })
      .catch(() => {});
  }

  return (
    <div
      id="page-enroll"
      style={{
        position: insidePage ? "static" : "fixed",
        height: insidePage ? "auto" : "100vh",
        width: insidePage ? "100%" : "100vw",
        zIndex: insidePage ? 0 : 3,
        backgroundColor: insidePage ? "var(--light3)" : "var(--dark6-9",
      }}
      className={`flex h-center v-center ${popUpType === 2 ? " resend" : ""}`}
    >
      <div
        className="flex-column v-center content"
        style={{
          animation: props.showSignInAnimation ? "slide-down2 0.6s" : "none",
          boxShadow: insidePage ? "none" : "5px 5px 10px var(--shadow)",
          overflowY: insidePage ? "auto" : "scroll",
        }}
      >
        {!insidePage && <CgClose className="close" onClick={closeSignIn} />}
        <h2>{props.management ? "管理員" : "會員"}登入</h2>
        <div className="flex-column v-start text-inputs">
          <a>
            帳號<span>*</span>
          </a>
          <input
            type="text"
            ref={userName}
            className={inValid ? "invalid-input" : ""}
          />
          <a>
            密碼<span>*</span>
          </a>
          <div className="flex-column password">
            <input
              type={showPassword ? "text" : "password"}
              ref={password}
              className={inValid ? "invalid-input" : ""}
            />
            {showPassword ? (
              <HiOutlineEyeOff className="icon" onClick={updatePasswordIcon} />
            ) : (
              <HiOutlineEye className="icon" onClick={updatePasswordIcon} />
            )}
          </div>
          {props.showForgetFileds &&
            <div className="flex h-center forgot">
              <a className="forgot-pass" onClick={showForgotUserName}>
                忘記帳號
              </a>
              <a className="forgot-pass" onClick={showForgotPassword}>
                忘記密碼
              </a>
            </div>
          }
          {<div className="flex h-center captcha">
            <HCaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onError={() =>
                setError("網頁錯誤, 請刷新頁面或與我們聯絡: " + contactEmail)
              }
              onExpire={() => setError("驗證過期, 請刷新頁面")}
              onVerify={setToken}
            />
          </div>}
        </div>
        {error !== null && <a className="error-msg">{error}</a>}
        <button className="button1 submit" onClick={handleSubmit}>
          確認
        </button>
        {!props.management && <div className="divider"></div>}
        {!props.management && (
          <div className="flex h-around social">
            <button
              className="flex h-center v-center button2 button-line"
              onClick={lineSignIn}
            >
              <FaLine />
              Line登入
            </button>
            {/* <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_IP}
              fields="name,email"
              language="zh_TW"
              cssClass="flex h-center v-center button2 button-fb"
              textButton="FB登入"
              icon={<FaFacebook />}
              callback={responseFacebook}
            /> */}
            {/* <button className="flex h-center v-center button2 button-google">
            <FaGoogle />
            Google登入
          </button> */}
          </div>
        )}
        {!props.management && (
          <button className="button1 sign-up" onClick={showSignUp}>
            加入會員
          </button>
        )}
      </div>
      {showPopUp && (
        <PopUp popUpContent={getPopUpContent} hidePopUp={getHidePopUp} />
      )}
    </div>
  );
}
